import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private themeKey = 'currentTheme';

  constructor() {
    this.loadTheme(); // Load the theme during initialization
  }

  // Switch between dark and light themes
  switchTheme(isDarkMode: boolean): void {
    const theme = isDarkMode ? 'dark' : 'light';
    document.documentElement.setAttribute('data-bs-theme', theme);
    sessionStorage.setItem(this.themeKey, theme);
  }

  // Load and apply the theme from session storage
  loadTheme(): void {
    const savedTheme = sessionStorage.getItem(this.themeKey) || 'light';
    document.documentElement.setAttribute('data-bs-theme', savedTheme);
  }

  // Get current theme state from session storage
  getCurrentTheme(): boolean {
    const savedTheme = sessionStorage.getItem(this.themeKey) || 'light';
    return savedTheme === 'light'; // Return true if light theme, false if dark
  }
}
